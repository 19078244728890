var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table__container"},[_c('div',{staticClass:"transaction__filter--container bg-white py-2"},[_c('div',{staticClass:"d-flex align-items-center search__export w-100"},[_c('div',{staticClass:"search__input w-100 px-2"},[_c('b-input-group',[_c('b-form-input',{attrs:{"placeholder":"Cari nama produk"},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1)],1)],1)])]),(_vm.isLoading == true)?_c('div',{staticClass:"d-flex justify-content-center mb-1 mt-5"},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1):_c('vue-good-table',{staticClass:"bg-white",attrs:{"max-height":"80vh","columns":_vm.columns,"rows":_vm.rows.data,"fixed-header":false,"sort-options":{
      enabled: false,
    },"pagination-options":{
      enabled: false,
    }},scopedSlots:_vm._u([{key:"table-column",fn:function(props){return [(props.column.field == 'action')?_c('span'):(props.column.field == 'avg_purchase_price')?_c('span',[(_vm.checkPermission('harga modal'))?_c('span',[_vm._v(" "+_vm._s(props.column.label)+" ")]):_vm._e()]):(props.column.field == 'formatted_id')?_c('span',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"12px","cursor":"pointer"},on:{"click":function () {
            _vm.filter.sort_by = null;
            _vm.filter.sort_type == 'desc'
              ? (_vm.filter.sort_type = 'asc')
              : (_vm.filter.sort_type = 'desc');
          }}},[_c('span',[_vm._v(" "+_vm._s(props.column.label)+" ")]),(_vm.filter.sort_by == null)?_c('b-img',{staticClass:"filter-image",class:_vm.filter.sort_type == 'asc' ? 'rotate-180' : '',staticStyle:{"width":"12px"},attrs:{"src":require('@/assets/images/icons/Icon-order-list-down.svg'),"alt":"Icon-order-list-down","role":"button"}}):_vm._e()],1):(props.column.field == 'name')?_c('span',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"12px","cursor":"pointer"},on:{"click":function () {
            _vm.filter.sort_by = 'alias_name';
            _vm.filter.sort_type == 'desc'
              ? (_vm.filter.sort_type = 'asc')
              : (_vm.filter.sort_type = 'desc');
          }}},[_c('span',[_vm._v(" "+_vm._s(props.column.label)+" ")]),(_vm.filter.sort_by == 'alias_name')?_c('b-img',{staticClass:"filter-image",class:_vm.filter.sort_type == 'asc' ? 'rotate-180' : '',staticStyle:{"width":"12px"},attrs:{"src":require('@/assets/images/icons/Icon-order-list-down.svg'),"alt":"Icon-order-list-down","role":"button"}}):_vm._e()],1):_c('span',[_c('span',[_vm._v(" "+_vm._s(props.column.label)+" ")])])]}},{key:"table-row",fn:function(props){return [(props.column.field === 'standard_price')?_c('span',[_c('span',[_vm._v(" "+_vm._s(_vm._f("formatAmount")(props.row.standard_price))+" ")])]):(props.column.field === 'warehouse_names')?_c('span',{attrs:{"id":"warehouse_names"}},[(props.row.warehouse_names)?_c('div',[(props.row.warehouse_names.length <= 3)?_c('span',[_vm._v(_vm._s(props.row.warehouse_names .map(function (e) { return e; }) .slice(0, 3) .join(", ")))]):_c('b-dropdown',{staticClass:"px-0",attrs:{"text":props.row.warehouse_names
                .map(function (e) { return e; })
                .slice(0, 3)
                .join(', '),"variant":"flat-secondary px-0"}},[_c('b-row',{staticClass:"mx-0 pt-1"},_vm._l((props.row.warehouse_names),function(item,index){return _c('b-col',{key:index},[_c('h6',{staticClass:"text-dark"},[_vm._v(_vm._s(item))])])}),1)],1)],1):_vm._e()]):(props.column.field === 'avg_purchase_price')?_c('span',[(_vm.checkPermission('harga modal'))?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatAmount")(props.row.avg_purchase_price))+" ")]):_vm._e()]):(props.column.field === 'minimum_price')?_c('span',[_c('span',[_vm._v(" "+_vm._s(_vm._f("formatAmount")(props.row.minimum_price))+" ")])]):(props.column.field === 'action')?_c('span',[(
            _vm.checkPermission('update produk') |
              _vm.checkPermission('delete produk')
          )?_c('span',[_c('b-dropdown',{staticClass:"d-flex",attrs:{"id":"dropdown-dropleft","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[(_vm.checkPermission('update produk'))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.goToDetail(props)}}},[_c('span',[_vm._v(" Edit ")])]):_vm._e(),(_vm.checkPermission('delete produk'))?_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteData(props.row.uuid)}}},[_c('span',[_vm._v(" Hapus ")])]):_vm._e()],1)],1):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),(_vm.rows && _vm.isLoading == false)?_c('div',{staticClass:"d-flex justify-content-between align-items-center flex-wrap bg-white border",staticStyle:{"padding":"0.8rem"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['10', '15', '20']},model:{value:(_vm.filter.per_page),callback:function ($$v) {_vm.$set(_vm.filter, "per_page", $$v)},expression:"filter.per_page"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" Tampilkan "+_vm._s(_vm.rows.total > 0 ? _vm.filter.per_page * (_vm.rows.current_page - 1) + 1 : 0)+" ke "+_vm._s(_vm.rows.total > 0 ? Math.min(_vm.filter.per_page * _vm.rows.current_page, _vm.rows.total) : 0)+" dari "+_vm._s(_vm.rows.total)+" transaksi. ")])],1),_c('div',[_c('b-row',{staticClass:"mx-0 align-items-center"},[_c('b-col',[_c('pagination',{staticClass:"mb-0",attrs:{"data":_vm.rows,"limit":4,"align":"right"},on:{"pagination-change-page":_vm.getDataProduct}})],1)],1)],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }